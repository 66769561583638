import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import styles from "./articleHeader.module.scss"
import HeaderImage from "../headerImage"

const pad = num => {
  return num < 10 ? "0" + num.toString() : num.toString()
}

const ArticleHeader = props => {
  const data = useStaticQuery(
    graphql`
      query {
        craft {
          issues: categories(
            group: "athenaIssues"
            orderBy: "dateCreated asc"
          ) {
            id
          }
        }
      }
    `
  )

  // This returns a two-digit number of the issue order based on how the categories are order in Craft
  const issueNumber = pad(
    data.craft.issues.findIndex(issue => issue.id === props.issue[0].id) + 1
  )
  const issueTitle = props.issue[0].title

  return (
  
    <header className="wrapper">
      <div className="text-center">
        <div className={(issueNumber === '04' ? styles.fixedHeader : styles.header)}>
          <div className={styles.issue}>
            <p className="a-h-5">
              Issue {issueNumber} / {issueTitle}
            </p>
          </div>
          <div className={styles.title}>
            <div className="columns is-centered no-vertical-gap">
              <div className="column is-9">
                <h1 className="a-h-display">{props.title}</h1>
                
              </div>
            </div>
          </div>
          <div className={styles.subtitle} itemProp="subheading">
            <p className="a-h-5">{props.subtitle}</p>
          </div>
        </div>
        {props.coverImages && <HeaderImage data={props.coverImages} />}
      </div>
    </header>
  )
}

export default ArticleHeader
