import React from "react"
import useMedia from "../../../../hooks/useMedia"
import {useSpring, animated, easings } from '@react-spring/web'
import styles from './joy-text.module.scss'

const ExplorationAndPlay = () => {

  let media = useMedia()
  let desktop = media(">desktop")
  let tablet = media(">tablet")
  const desk = "translate(0,-186px)"
  const tab = "translate(0,-135px)"
  const mob = "translate(0, -120)"

  const down = useSpring({
    to: { transform: desktop ? desk : tablet ? tab : mob},
    from: { transform: "translate(0,0px)"},
    config: { duration: 1000, easing: easings.easeOutQuint },
  })
  const up = useSpring({
    to: {transform: "translate(0,0px)" },
    from: {transform: "translate(0,-186px)" },
    config: { duration: 2500, easing: easings.easeOutQuint },
  })
  const fade = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { duration: 1000, easing: easings.easeOutQuint },
  })
  const spin = useSpring({
    to: {transform: "scale(1, 1)"},
    from: {transform: "scale(-1, 1)" },
    config: { duration: 900, easing: easings.easeOutQuint },
  })
  return (
  <div className={styles.scrollerContainer}>
    <div className={styles.scroller}>
      <animated.div style={up}>
        <span>E</span><span>B</span><span>R</span><span>L</span>
      </animated.div>
      <animated.div style={down}>
        <b>P</b><b>p</b><b>N</b><b>M</b>
      </animated.div>
      <animated.div style={up}>
        <b>B</b><b>e</b><b>p</b><b>c</b>
      </animated.div>
      <animated.div style={up}>
        <b>R</b><b>B</b><b>P</b><b>L</b>
      </animated.div>
      <animated.div style={fade}>
        <b>A</b>
      </animated.div>
      <animated.div style={down}>
        <span>j</span><span>e</span><span>n</span><span>C</span>
      </animated.div>
      <animated.div style={up}>
        <b>I</b><b>i</b><b>:</b><b>.</b>
      </animated.div>
      <animated.div style={fade}>
        <b>N</b>
      </animated.div>
      <animated.div style={down}>
        <span>e</span><span>C</span><span>8</span><span>G</span>
      </animated.div>
    </div>
      <div className={styles.scroller}>
        <animated.div style={up}>
          <b>V</b><b>B</b><b>P</b><b>L</b>
        </animated.div>
        <animated.div style={down}>
          <b>P</b><b>p</b><b>N</b><b>U</b>
        </animated.div>
        <animated.div style={up}>
          <b>L</b><b>B</b><b>P</b><b>L</b>
        </animated.div>
        <animated.div style={down}>
          <span>A</span><span>0</span><span>P</span><span>N</span>
        </animated.div>
        <animated.div style={up}>
          <b>E</b><b>e</b><b>p</b><b>c</b>
        </animated.div>
        <animated.div style={fade}>
          <b>R</b>
        </animated.div>
        <animated.div style={spin}>
          <b>A</b>
        </animated.div>
        <animated.div style={down}>
          <span>a</span><span>u</span><span>:</span><span>B</span>
        </animated.div>
        <animated.div style={fade}>
          <b>I</b>
        </animated.div>
        <animated.div style={up}>
          <b>L</b><b>i</b><b>:</b><b>-</b>
        </animated.div>
        <animated.div style={fade}>
          <b>I</b>
        </animated.div>
        <animated.div style={fade}>
          <b>T</b>
        </animated.div>
        <animated.div style={fade}>
          <span>Y</span>
        </animated.div>
      </div>
      
      <div className={styles.scroller}>
        <animated.div style={fade}>
          <span>A</span>
        </animated.div>
        <animated.div style={down}>
          <b>A</b><b>P</b><b>*</b><b>N</b>
        </animated.div>
        <animated.div style={spin}>
          <span>D</span>
        </animated.div>
      </div>

      <div className={styles.scroller}>
        <animated.div style={up}>
          <b>R</b><b>i</b><b>*</b><b>A</b>
        </animated.div>
        <animated.div style={fade}>
          <span>A</span>
        </animated.div>
        <animated.div style={down}>
          <b>A</b><b>i</b><b>*</b><b>D</b>
        </animated.div>
        <animated.div style={down}>
          <b>l</b><b>i</b><b>:</b><b>I</b>
        </animated.div>
        <animated.div style={down}>
          <span>j</span><span>e</span><span>n</span><span>C</span>
        </animated.div>
        <animated.div style={fade}>
          <b>A</b>
        </animated.div>
        <animated.div style={up}>
          <b>L</b><b>i</b><b>:</b><b>-</b>
        </animated.div>
      </div>

      <div className={styles.scroller}>
        <animated.div style={up}>
          <b>H</b><b>i</b><b>*</b><b>A</b>
        </animated.div>
        <animated.div style={fade}>
          <em>O</em>
        </animated.div>
        <animated.div style={down}>
          <b>A</b><b>i</b><b>*</b><b>N</b>
        </animated.div>
        <animated.div style={down}>
          <b>l</b><b>i</b><b>:</b><b>E</b>
        </animated.div>
        <animated.div style={down}>
          <span>j</span><span>e</span><span>n</span><span>S</span>
        </animated.div>
        <animated.div style={fade}>
          <b>T</b>
        </animated.div>
        <animated.div style={up}>
          <b>Y</b><b>i</b><b>:</b><b>-</b>
        </animated.div>
      </div>
    </div>
  )
}
export default ExplorationAndPlay